import { render, staticRenderFns } from "./NonprofitAbout.vue?vue&type=template&id=81befb94&scoped=true&"
import script from "./NonprofitAbout.vue?vue&type=script&lang=js&"
export * from "./NonprofitAbout.vue?vue&type=script&lang=js&"
import style0 from "./NonprofitAbout.vue?vue&type=style&index=0&id=81befb94&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81befb94",
  null
  
)

export default component.exports